const Messages = {
    loginFailed: {code:100,message:'Benutzername oder Passwort falsch'},
    unauthorized: {code:101,message:'no valid token'},
    AuthFailed:{code:102, message:'Autorisierung fehlgeschlagen'},
    error:{code:103 , message: 'Da ist wohl etwas schief gelaufen , bitte kontaktieren Sie den Server-Admin!'},
    missingValuesProject:{code:104 , message: 'Sie haben kein Projekt gewählt ! Bitte wählen Sie ein Projekt aus um die Zeiterfassung zu starten.'},
    missingValuesStatus:{code:105 , message: 'Sie haben keinen Status gewählt ! Bitte wählen Sie einen Status aus um die Zeiterfassung zu starten.'},
    confirmDeleteText:{code:106 , message: 'Möchten Sie diesen Eintrag wirklich löschen ?'},
}

export default Messages