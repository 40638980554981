import { React, useState, useEffect } from 'react'
import { Validation } from '../functions/Validation';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'universal-cookie';
import './Login.css'

const Login = () => {

    const cookies = new Cookies()
    const navigate = useNavigate()
    const [loginData, setLoginData] = useState()

    useEffect(() => {
        (async () => {
            try {
                // setLoading(LoadingState.Active)
                await Validation()
                navigate("/app")
            } catch (error) {
                navigate("/")
            }
        })()
    }, [])

    const inputListenerFunc = (e) => {
        const { name, value } = e.target
        setLoginData({ ...loginData, [name]: value })
    }

    const loginSubmit = (e) => {
        e.preventDefault()
        axios.post(process.env.REACT_APP_API_URL + "/login/login.php", loginData)
            .then((response) => {
                const options = {
                    // httpOnly: true, //TODO im Online Betrieb auskommentieren
                    //secure: true,
                    path: '/',
                    expires: new Date(Date.now() + 1000 * 60 * 60 * 5)
                }
                cookies.set("token", response.data)
                navigate('/app')
            })
            .catch((error) => {
                alert('Login Fehlgeschlagen')
            })
    }

    return (
        <div className='LoginPage'>
            <div className='LoginScreenBox'>
                <h1 className='headerH1'>Login</h1>
                <div className='formBox'>
                    <form onSubmit={loginSubmit}>
                        <input type='text' className='input-field-m' placeholder='Username' name='userId' onChange={inputListenerFunc}></input>
                        <input type='password' autoComplete='true' className='input-field-m mt-3' placeholder='Password' name='password' onChange={inputListenerFunc}></input>
                        <button type="submit" className='btn btn-success'>Login</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login