import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import Login from './Pages/Login';


function approuter() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/app' element={<App />} />
            </Routes>
        </BrowserRouter>
    );
}

export default approuter;