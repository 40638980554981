import React, { useState } from 'react'
import './SearchBar.css'

function SearchBar(props) {
    const { hosting_data } = props
    const allCompanies = document.querySelectorAll('.companie_card')

    const [searchFilter, setSearchFilter] = useState("")

    const inputListener = (e) => {
        setSearchFilter(e.target.value.toLowerCase())

        if (e.target.value !== '') {
            searchCompany(e)
        } else {
            setAllVisible()
        }
    }

    const setAllVisible = () => {
        allCompanies.forEach((company_card) => {
            company_card.style.display = 'block'
        })
    }

    const searchCompany = (e) => {
        hosting_data.filter((company) => {
            company.name = company.name.toLowerCase()
            // ## TODO: Fix this its working but not as expected
            if (company.name.includes(searchFilter)) {
                allCompanies.forEach((company_card) => {
                    if (company_card.id === "cardID-" + company.id) {
                        company_card.style.display = 'block'
                    } else {
                        company_card.style.display = 'none'
                    }
                })
            }
            return true;
        })
    }

    return (
        <div className='searchbox'>
            <input type='text' placeholder='Search...' onChange={inputListener} name="searchFilter" />
        </div>
    )
}

export default SearchBar