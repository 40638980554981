import React from 'react';
import UptimeChart from './UpTimeChart/UptimeChart';
import './MonitorChart.css';

function MonitorChart(props) {
  const { showStatsWindow, setShowStatsWindow, showStatsData, setShowStatsData, token } = props;


  return (
    <>
      <h2>Diagramm</h2>
      {showStatsWindow && 
      <div className="kss_hosting_char">
        <UptimeChart showStatsData={showStatsData} token={token}/> 
      </div>
      }
    </>
  );
}

export default MonitorChart