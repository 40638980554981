import './App.css';
import MonitorStats_Chart from './components/window_chart/Monitor_chart';
import MonitorStats from './components/window_stats/MonitorStats';
import TrackingList from './components/window_tacking_list/TrackingList';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Validation } from './functions/Validation';
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import LoadingState from './includes/enums/LoadingState';
import LoadingMessages from './includes/enums/LoadingMessages';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

function App() {
  const navigate = useNavigate()

  const cookies = new Cookies()
  const token = cookies.get('token')

  const [hosting_data, setHostingData] = useState([]);
  const [showStatsWindow, setShowStatsWindow] = useState(false);
  const [showStatsData, setShowStatsData] = useState(null);
  const [loading,setLoading] = useState(LoadingState.Inactive)
  const [statsLoading, setStatsLoading] = useState(LoadingState.Inactive)




  useEffect(() => {
    (async () => {
      try {
        setLoading(LoadingState.Active)
        await Validation()
        await getAppData()
        
      } catch (error) {
        console.log(error)
        navigate("/")
      }
    })()
  }, [])

  const getAppData = async () => {
    await axios.get(process.env.REACT_APP_API_URL + '/app_data/app_data.php', { headers: { 'Authorization': "Bearer " + token } } )
      .then((response) => {
        const requested_data = response.data
        setHostingData(requested_data);
        setLoading(LoadingState.Inactive)
      })

      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <div className="app">
      {loading === LoadingState.Active && <LoadingScreen text={LoadingMessages.GeneralWaiting}/>}
      <h1>KSS Hosting Monitor</h1>
      <div className="app__inner">
        <div className='app__left_side'>
          <div className='app__left_side_inner'>
            <TrackingList hosting_data={hosting_data} setHostingData={setHostingData} setShowStatsWindow={setShowStatsWindow} setShowStatsData={setShowStatsData} setStatsLoading={setStatsLoading}/>
          </div>
        </div>
        <div className='app__right_side'>
          <div className='app__right_side__top'>
            <MonitorStats_Chart showStatsWindow={showStatsWindow} setShowStatsWindow={setShowStatsWindow} showStatsData={showStatsData} setShowStatsDat={setShowStatsData} token={token}/>
          </div>
          <div className='app__right_side__bottom'>
            <MonitorStats showStatsData={showStatsData} showStatsWindow={showStatsWindow} setShowStatsWindow={setShowStatsWindow} statsLoading={statsLoading} setStatsLoading={setStatsLoading}  hosting_data={hosting_data} setHostingData={setHostingData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
