import Cookies from 'universal-cookie'
import axios from 'axios'
import Messages from '../includes/enums/Messages'


export const Validation = async () => {
    const cookies = new Cookies()
    const token = cookies.get('token')

    if (typeof token === 'undefined') {
        throw Messages.unauthorized.message
    }

    return axios.get(process.env.REACT_APP_API_URL + "/login/checkAuth.php", { headers: { 'Authorization': "Bearer " + token } })
        .catch((error) => {
            if (error.response.status === 401) {
                throw Messages.unauthorized.message
            } else {
                throw Messages.error.message
            }
        })
}