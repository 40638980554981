import React from 'react'
import SearchBar from './SearchBar/SearchBar'
import ToolBar from './ToolBar/ToolBar'
import './TrackListHeader.css'

function TrackListHeader(props) {
    const {setWindowMode,hosting_data,setHostingData} = props
  return (
    <div className='tracklist__header'>
        <h2>Kundenliste</h2>
        <SearchBar hosting_data={hosting_data} setHostingData={setHostingData}/>
        <ToolBar setWindowMode={setWindowMode}/>
    </div>
  )
}

export default TrackListHeader