import React, { useState } from 'react'
import axios from 'axios'
import './AddPage.css'
import Cookies from 'universal-cookie'

function AddPage(props) {
    const { setWindowMode, setHostingData, hosting_data } = props

    const cookies = new Cookies()
    const token = cookies.get('token')
    
    const [inputData, setInputData] = useState({ name: null, url: null, createDailyBackup: null})
    const [isChecked, setIsChecked] = useState(true);

    const inputListener = (event) => {
        if(event.target.name === 'createDailyBackup'){
            setIsChecked(!isChecked)
            setInputData({
                ...inputData,
                [event.target.name]: isChecked
            })
            return
        }
        setInputData({
            ...inputData,
            [event.target.name]: event.target.value
        })
    }

    const validateInput = () => {
        if (inputData.name === null || inputData.url === null) {
            return false
        } else {
            return true
        }
    }

    const validateUrl = (url) => {
        const urlRegex = /^(http|https):\/\/[^ "]+$/
        if (url.match(urlRegex)) {
            return true
        } else {
            return false
        }
    }

    const saveData = async (event) => {
        event.preventDefault()
        if (validateInput() && validateUrl(inputData.url)) {
            await axios.post(process.env.REACT_APP_API_URL + '/app_data/app_data.php', inputData , { headers: { 'Authorization': "Bearer " + token } })
                .then((response) => {
                    const id = response.data.id
                    const manipulatedData = {
                        id: id,
                        name: inputData.name,
                        url: inputData.url,
                        uptime: 0,
                        pingData: {
                            status_code: 0,
                            response_time: 0,
                            packet_loss: 0,
                            timestamp: 0,
                            website_id: id
                        },
                    }
                    // add input data to the existing data
                    setHostingData([...hosting_data, manipulatedData])
                    cancel(event)
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            alert('Input not valid');
        }
    }

    const cancel = (e) => {
        e.preventDefault()
        setWindowMode({
            list: true,
            addPage: false,
            settings: false,
            export: false,
            import: false
        })
    }

    return (
        <div className='addPage_window'>
            <div className='addPage_form'>
                <form onSubmit={saveData}>
                    <label htmlFor='companyName'>Company Name:<br />
                        <input type='text' id='companyName' name='name' onChange={inputListener} />
                    </label>

                    <label htmlFor='url'>URL:<br />
                        <input type='text' id='url' name='url' onChange={inputListener} />
                        <br/>URL must start with http:// or https://
                    </label>
                    
                    <label htmlFor='createDailyBackup'>Backups<br />
                        <input type='checkbox' id='createDailyBackup' name='createDailyBackup' onChange={inputListener}/>
                        <span>Create Daily Backup</span>
                    </label>

                    <button className='addPage_form__save_button'>Save</button>
                    <button className='addPage_form__cancel_button' onClick={(event) => cancel(event)}>Cancel</button>
                </form>
            </div>
        </div>
    )
}

export default AddPage