import React from 'react'
import './ToolBar.css'

function ToolBar(props) {
    const { setWindowMode } = props

    const refresh = () => {
        window.location.reload()
    }

    return (
        <div className='tracking_list_toolbar'>
            <div className='tools'>
                <button className='toolbar_btn addPage' onClick={() => setWindowMode({ list: false, addPage: true, settings: false, export: false, import: false })}>
                    +
                </button>
                <button className='toolbar_btn refresh' onClick={refresh}>Refresh</button>
            </div>
        </div>
    )
}

export default ToolBar