import React, { useEffect, useState } from 'react'
import './MonitorStats.css'
import axios from 'axios'
import Cookies from 'universal-cookie'
import LoadingState from '../../includes/enums/LoadingState'
import LoadingMessages from '../../includes/enums/LoadingMessages'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import Messages from '../../includes/enums/Messages'

function MonitorStats(props) {
  const { showStatsData, showStatsWindow, hosting_data, setHostingData, setStatsLoading, statsLoading } = props

  const cookies = new Cookies()
  const token = cookies.get('token')
  const confirmText = Messages.confirmDeleteText.message

  const [getStatsData, setGetStatsData] = useState(null)

  useEffect(() => {
    getStats(showStatsData)
  }, [showStatsData])

  const getStats = async (data) => {
    if (data === null) return
    await axios.get(process.env.REACT_APP_API_URL + '/stats/stats.php?id=' + data.id, { headers: { 'Authorization': "Bearer " + token } })
      .then((response) => {
        setStatsLoading(LoadingState.Inactive)
        setGetStatsData(response.data)
        // ## TODO: set the response data to the state
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const deleteRow = (data) => {
    let cleanded_data = []
    if (window.confirm(confirmText)) {
      axios.delete(process.env.REACT_APP_API_URL + '/app_data/app_data.php', {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
          id: data.id
        }
      })
        .then(() => {
          hosting_data.filter((company) => {
            if (company.name !== data.name) {
              cleanded_data.push(company)
            }
            return true;
          })
          setHostingData(cleanded_data)
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  const start_Backup = () => {
    if (window.confirm('Are you sure you want to start a backup?')) {
      axios.post(process.env.REACT_APP_API_URL + '/backupAPI/backupAPI.php', showStatsData, { headers: { 'Authorization': `Bearer ${token}` } })
        .then((response) => {
          alert('Backup wurde gestartet. Bitte warten Sie bis der Backup Prozess abgeschlossen ist.')
        })
        .catch((error) => {
          alert('Login Fehlgeschlagen')
        })
    }
  }

  const calcUptime = (data) => {
    let uptime = data.goodPings ?? 0
    let total = data.allPings ?? 0
    let result = total !== 0 ? (uptime / total * 100).toFixed(2) : 0
    return result
  }

  const checkResponseTime = (response_time) => {
    let avg
    if (response_time === null) {
      avg = 0
    } else {
      avg = response_time.toFixed(5)
    }
    return avg
  }

  return (
    <>
      {statsLoading === LoadingState.Active && <LoadingScreen text={LoadingMessages.GeneralWaiting} />}
      {showStatsWindow && !statsLoading ?
        <div className='kss_hosting_stats_window'>
          <div className='kss_hosting_stats_window__header'>
            <h2>Statistik</h2>
            <h4>Last Ping: <span className='no-bold'>{getStatsData.lastPing ?? "-"}</span></h4>
          </div>
          <div className='kss_hosting_stats'>
            <div className='kss_hosting_stats__item'>
              <h3>Uptime</h3>
              <p>{calcUptime(getStatsData) + "%" ?? ""}</p>
            </div>
            <div className='kss_hosting_stats__item'>
              <h3>Last Respone Time</h3>
              <p>{checkResponseTime(showStatsData.pingData.response_time)}ms</p>
            </div>
            <div className='kss_hosting_stats__item'>
              <h3>AVG. Response Time</h3>
              <p>{checkResponseTime(getStatsData.averageResponseTime)}ms</p>
            </div>
            <div className='kss_hosting_stats__item'>
              <h3>Downtimes last 24h</h3>
              <p>{getStatsData.badPingsLast24h ?? ""}</p>
            </div>
            <div className='kss_hosting_stats__item'>
              <h3>Last Down Time</h3>
              <p>{getStatsData.lastBadPing ?? "-"}</p>
            </div>
            <div className='kss_hosting_stats__item'>
              <h3>Free Slot</h3>
              <p>-</p>
            </div>
          </div>
          <div className='stats_toolbar'>
            <button className='statsWindowButton __LinkChecker' onClick={() => console.log('wip:implent BrokenLinkCheckService')}>Broken Link Check</button>
            <button className='statsWindowButton __Backup' onClick={() => start_Backup()}>Backup </button>
            <button className='statsWindowButton __delete' onClick={() => deleteRow(showStatsData)}>Delete</button>
          </div>
        </div>
        :
        <div className='no_item_seleceted'>
          <div>
            <h2>Statistik</h2>
            <p>No Item selected</p>
          </div>
        </div>
      }
    </>
  )
}

export default MonitorStats