// Application Imports
import React, { useEffect, useState } from 'react'
import axios from 'axios'

// Styling Imports
import './TrackingList.css'
import '../mondal/ModalHTML_TrackingList.css'
import '../mondal/ModalHTML_Stats.css'
import Cookies from 'universal-cookie'
import LoadingState from '../../includes/enums/LoadingState'
import TrackListHeader from './windowHeader/TrackListHeader'
import AddPage from './windowHeader/ToolBar/components/AddPage'

function TrackingList(props) {
    const { hosting_data, setHostingData, setShowStatsWindow, setShowStatsData, setStatsLoading } = props

    const cookies = new Cookies()
    const token = cookies.get('token')

    const checkBoxButton = document.getElementById('createDailyBackup-checkBox')

    const [windowMode, setWindowMode] = useState({
        list: true,
        addPage: false,
        settings: false,
        export: false,
        import: false
    })
    const [ModalData, setModalData] = useState()
    const [editMode, setEditMode] = useState(false)
    const [isChecked, setIsChecked] = useState(true);

    const renderStatusLamp = (status) => {
        switch (status) {
            case 200:
                return <span className='lamp_row'>{status}<div className='status_controll_lamp __green'></div></span>
            case 301:
                return <span className='lamp_row'>{status}<div className='status_controll_lamp __yellow'></div></span>
            default:
                return <span className='lamp_row'>{status}<div className='status_controll_lamp __red'></div></span>
        }
    }

    const openEditModal = (data) => {
        setEditMode(true);
        setModalData(data)
    }


    const closeModal = () => {
        setEditMode(false)
    }

    const updateData = (e) => {
        e.preventDefault()
        axios.put(process.env.REACT_APP_API_URL + '/app_data/app_data.php', ModalData, { headers: { 'Authorization': "Bearer " + token } })
            .then(() => {
                setEditMode(false)
                updateArray(ModalData)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const modalInputChange = (e) => {
        if (e.target.name === 'createDailyBackup') {
            setIsChecked(!isChecked)
            setModalData({
                ...ModalData,
                [e.target.name]: isChecked
            })
            return
        }
        setModalData({ ...ModalData, [e.target.name]: e.target.value })
    }

    const updateArray = (data) => {
        let updatedArray = hosting_data.map((company) => {
            if (company.id === data.id) {
                return data
            } else {
                return company
            }
        })
        setHostingData(updatedArray)
    }

    const showInfoStats = (data, e) => {
        e.preventDefault()
        setStatsLoading(LoadingState.Active)
        setShowStatsWindow(true)
        setShowStatsData(data)

        // Set Border to active card
        const company_cards = document.querySelectorAll('.companie_card')
        const clicked_company_card = e.target.parentElement.parentElement.parentElement.parentElement.parentElement
        company_cards.forEach((card) => {
            if (card.classList.contains('active')) {
                card.classList.remove('active')
            } else {
                clicked_company_card.classList.add('active')
            }
        })
    }

    const getCheckdStatus = (status) => {
        if (status === 1) {
            return checkBoxButton.value = true
        }
        return checkBoxButton.value = false
    }

    return (
        <>
            {editMode &&
                <div id="kss_trackinListModal" className="modal">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className="modal-content">
                        <form onSubmit={updateData}>
                            <input type='text' value={ModalData.name} onChange={modalInputChange} name="name" />
                            <input type='text' value={ModalData.url} onChange={modalInputChange} name="url" />
                            <label htmlFor='createDailyBackup'>Backups<br />
                                <input onClick={modalInputChange} type='checkbox' name="createDailyBackup" id="createDailyBackup-checkBox"/>
                                <span>Create Daily Backup</span>
                            </label>
                            <button type='submit'>Save</button>
                        </form>
                    </div>
                </div>
            }
            {windowMode.addPage && <AddPage setWindowMode={setWindowMode} setHostingData={setHostingData} hosting_data={hosting_data} />}
            <TrackListHeader setWindowMode={setWindowMode} hosting_data={hosting_data} setHostingData={setHostingData} />
            {windowMode.list && <>
                <div>
                    {hosting_data && hosting_data.map((company, index) => {
                        return (
                            <div className='companie_card' key={index} id={"cardID-" + company.id}>
                                <h2>{company.name}</h2>
                                <table className='monitor_data_table' >
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Response Time</th>
                                            <th>Uptime</th>
                                            <th>URL</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='up_lamp_row'>{renderStatusLamp(company.pingData.status_code)}</td>
                                            <td>{company.pingData.response_time.toFixed(5)}ms</td>
                                            <td>{company.uptime.toFixed(2)} %</td>
                                            <td><a href={company.url}>{company.url}</a></td>
                                            <td><button className='companie_card_btn __edit' onClick={() => openEditModal(company)}>Edit</button></td>
                                            <td><button className='companie_card_btn __info' onClick={(e) => showInfoStats(company, e)}>Info</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })}
                </div>
            </>
            }
        </>
    )
}

export default TrackingList