// src/UptimeChart.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const UptimeChart = (props) => {

    const { showStatsData, token } = props;

    const [chartData, setChartData] = useState([
        { timestamp: '', status_code: 0 }
    ])

    useEffect(() => {
        getTimeData()
    }, [showStatsData])

    const getTimeData = async () => {
            await axios.get(process.env.REACT_APP_API_URL + '/chartStats/chartStats.php?id='+showStatsData.id , { headers: { 'Authorization': "Bearer " + token } })
                .then((response) => {
                    // console.log(response.data)
                    translateStatusCode(response.data)
                })

                .catch((error) => {
                    console.log(error);
                })
        }

    const translateStatusCode = (data) => {
        let arr = data

        arr.forEach((item) => {
            if (item.status_code === 200) {
                const pushObj = {
                    timestamp: item.timestamp,
                    status_code: 1
                }
                setChartData(chartData => [...chartData, pushObj])
            } else if (item.status_code === 301) {
                const pushObj = {
                    timestamp: item.timestamp,
                    status_code: 0
                }
                setChartData(chartData => [...chartData, pushObj])
            }else{
                const pushObj = {
                    timestamp: item.timestamp,
                    status_code: -1
                }
                setChartData(chartData => [...chartData, pushObj])
            }
        })
    }
    
    return (
        <ResponsiveContainer width="100%" height={350}>
            <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis
                    domain={[-2, 3]}  // Hier den gewünschten Wertebereich für die Y-Achse festlegen
                    tickCount={5}     // Anzahl der Tickmarken auf der Y-Achse
                />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    dataKey="status_code"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                    dot={false}  // Keine Punkte auf der Linie anzeigen
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default UptimeChart;
